@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  *letter-spacing: -1px;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a { text-decoration: none; color:#000; }

/* 팝업 선택자 고정 */
html:has(.list-modal.on),
body:has(.list-modal.on){
  overflow:hidden;
}


/* 스크롤바의 폭 너비 */
::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 6px;
  border-radius:20px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: #92FFCD; /* 스크롤바 색상 */
  border-radius:20px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track{
  background: #D9D9D9;  /*스크롤바 뒷 배경 색상*/
  border-radius:20px;
}
